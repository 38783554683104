import { StimChannelConfiguration } from '@egzotech/exo-session/features/electrostim';
import { logger } from 'helpers/logger';
import { EMSExerciseDefinition } from 'libs/exo-session-manager/core';

import { ChartDataSource as ChartDataSource } from './ChartDataSource';
import { ChannelIndex, ChannelMap, TimeLinePoint } from './types';

const TIME_REDUCTOR = 1000 * 1000; // convert us to seconds
type PreparedElectrostimData = {
  channelIndex: ChannelIndex;
  programTime: number;
  phases: StimChannelConfiguration[];
};

type PreparedElectrostimProgram = ChannelMap<PreparedElectrostimData>;

type NormalizedDataset = {
  runTime: number;
  phaseIndex: number;
  elements: {
    delayTime: number;
    riseTime: number;
    plateauTime: number;
    fallTime: number;
    pauseTime: number;
  };
};
/**
 * ElectrostimChartDataSource is responsible for:
 *  - analyzing all electrostim programs
 *  - creating an envelope of signal shape
 *
 * Output data format is suited for EChart library
 */
export class ElectrostimChartDataSource extends ChartDataSource {
  constructor() {
    super();
  }

  dispose() {
    super.dispose();
  }

  setProgram(exerciseDefinition: EMSExerciseDefinition, channelMapping: Record<number, number>) {
    const preparedProgram = this.prepareElectrostimProgram(exerciseDefinition, channelMapping);

    logger.info('ElectrostimChartTimelineGenerator.setProgram', 'preparedProgram', preparedProgram);
    this._timelines = this.analyzeElectrostimProgram(preparedProgram);
  }

  private prepareElectrostimProgram(exerciseDefinition: EMSExerciseDefinition, channelMapping: Record<number, number>) {
    const phases: PreparedElectrostimProgram = new ChannelMap();
    const emsProgram = exerciseDefinition.ems.program;

    const programTime = emsProgram.programTime;

    for (const _channelIndex of Object.keys(channelMapping)) {
      const channelIndex = parseInt(_channelIndex) as ChannelIndex;
      const sourceChannel = channelMapping[channelIndex];

      const defaultChannelValues = emsProgram.defaultChannelValues.find(v => v.channelIndex == sourceChannel);
      if (!defaultChannelValues) {
        continue;
      }
      const updatedChannelValues = { ...defaultChannelValues, channelIndex };

      for (const [_phaseIdx, phase] of Object.entries(emsProgram.phases)) {
        const phaseIdx = Number(_phaseIdx);

        const stimChannel = {
          ...updatedChannelValues,
          ...phase.channels[sourceChannel],
        };

        if (!phases.channelExists(channelIndex)) {
          phases.set(channelIndex, {
            programTime,
            channelIndex,
            phases: [],
          });
        }
        phases.get(channelIndex).phases[phaseIdx] = stimChannel;
      }
    }
    return phases;
  }

  private analyzeElectrostimProgram(program: PreparedElectrostimProgram) {
    const timelines: ChannelMap<TimeLinePoint[]> = new ChannelMap<TimeLinePoint[]>();

    for (const channelData of program.values()) {
      const programTime = channelData.programTime / TIME_REDUCTOR;
      let channelTimeOffset = 0;
      do {
        const channelIndex = channelData.channelIndex as ChannelIndex;
        if (!timelines.channelExists(channelIndex)) {
          timelines.set(channelIndex, []);
        }
        for (const [phaseIndex, phase] of Object.entries(channelData.phases)) {
          const prevPoint = timelines.get(channelIndex)?.at(-1);
          if (prevPoint) {
            channelTimeOffset = channelTimeOffset = prevPoint[0];
          }
          const normalizedDataset = this.normalizeDataset(phase, Number(phaseIndex));

          const result = this.electrostimConvertPhaseToPoints(normalizedDataset, channelTimeOffset);

          channelTimeOffset = result.at(-1)?.[0] ?? channelTimeOffset;

          timelines.get(channelIndex)?.push(...result);
        }
      } while (!programTime || channelTimeOffset < programTime);
    }
    return timelines;
  }

  private normalizeDataset(stimChannel: StimChannelConfiguration, phaseIndex: number) {
    let runTime = stimChannel.runTime / TIME_REDUCTOR;
    let dataSet: NormalizedDataset = {
      runTime,
      phaseIndex,
      elements: {
        delayTime: stimChannel.delay / TIME_REDUCTOR,
        riseTime: stimChannel.riseTime / TIME_REDUCTOR,
        plateauTime: (stimChannel.plateauTime ?? 0) / TIME_REDUCTOR,
        fallTime: stimChannel.fallTime / TIME_REDUCTOR,
        pauseTime: ((stimChannel.pauseTime ?? 0) - stimChannel.delay) / TIME_REDUCTOR,
      },
    };
    const calculatedTime = Object.values(dataSet.elements).reduce((acc, val) => acc + val, 0);
    runTime = runTime ?? calculatedTime;

    if (!calculatedTime) {
      dataSet = {
        runTime,
        phaseIndex,
        elements: {
          delayTime: 0,
          riseTime: 0,
          plateauTime: runTime,
          fallTime: 0,
          pauseTime: 0,
        },
      };
    }

    return dataSet;
  }

  private electrostimConvertPhaseToPoints(normalizeDataset: NormalizedDataset, timeOffset = 0): TimeLinePoint[] {
    const result: TimeLinePoint[] = [];

    const dataSetKeys = Object.keys(normalizeDataset.elements) as (keyof typeof normalizeDataset.elements)[];

    let value = 0;
    let index = 0;
    let currentPhaseTime = 0;

    result.push([currentPhaseTime + timeOffset, 0]);

    while (currentPhaseTime < normalizeDataset.runTime) {
      const key = dataSetKeys[index];
      currentPhaseTime =
        currentPhaseTime + normalizeDataset.elements[key] > normalizeDataset.runTime
          ? normalizeDataset.runTime
          : currentPhaseTime + normalizeDataset.elements[key];
      switch (key) {
        case 'riseTime':
        case 'plateauTime':
          value = 100;
          break;
        case 'fallTime':
        case 'pauseTime':
        case 'delayTime':
          value = 0;
      }
      result.push([currentPhaseTime + timeOffset, value]);

      index++;
      if (index >= dataSetKeys.length) {
        index = 0;
      }
    }
    return result;
  }
}
