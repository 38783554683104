import { BoxProps } from '@chakra-ui/react';
import { __ } from 'helpers/i18n';
import { useSignal, useSignalEffect } from 'helpers/signal';
import { EMGChartDataSource } from 'libs/chart-datasources/EMGChartDataSource';

import { TimelineChart } from 'components/timeline-charts/TimelineChart';

/**
 * Width in seconds of emg chart
 */
export const EMG_WINDOW_WIDTH = 20;

export const EMGTimelineChart = (
  props: { chartDataSource: EMGChartDataSource; yMax: number; hideChannels?: number[] } & BoxProps,
) => {
  const { chartDataSource, yMax, hideChannels, ...args } = props;
  const time = useSignal(chartDataSource.timeSource.duration ?? 0, '');
  // TODO: logic visualising emg actions is completed, use it with integration of emg biofeedback
  // trainings with rest of the application
  // const emgProgram = getPredefinedStellaBioEMGExercise()['emg_5swork_5srest'];

  useSignalEffect(() => {
    const interval = setInterval(() => {
      time.value = chartDataSource.timeSource.duration ?? 0;
    }, 50);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <TimelineChart
      chartDataSource={chartDataSource}
      time={time}
      options={{
        shiftDatasetsOnAxisY: false,
        showTimeMark: false,
        windowWidth: EMG_WINDOW_WIDTH,
        yAxisUnit: __('units.microvolts'),
        yAxisMax: yMax,
        hideChannels,
      }}
      {...args}
    />
  );
};
