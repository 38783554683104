import { useCallback, useEffect, useRef } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import colors, { Channel } from 'config/theme/colors';
import * as echarts from 'echarts';
import { MarkLine2DDataItemOption } from 'echarts/types/src/component/marker/MarkLineModel';
import { Signal } from 'helpers/signal';
import { ChannelMap, TimeLinePoint } from 'libs/chart-datasources';
import { EMGExerciseDefinition } from 'libs/exo-session-manager/core';

const getXPixelWidth = (echartsRef: echarts.ECharts) => {
  const valueRange = 10;
  const xMinPixel = echartsRef.convertToPixel({ xAxisIndex: 0 }, 0);
  const xMaxPixel = echartsRef.convertToPixel({ xAxisIndex: 0 }, valueRange);
  return valueRange / (xMaxPixel - xMinPixel);
};

const getYPixelWidth = (echartsRef: echarts.ECharts) => {
  const valueRange = 100;
  const yMinPixel = echartsRef.convertToPixel({ yAxisIndex: 0 }, 0);
  const yMaxPixel = echartsRef.convertToPixel({ yAxisIndex: 0 }, valueRange);
  return valueRange / (yMinPixel - yMaxPixel);
};

type EMGArrayElement = {
  time: number;
  threshold: number;
  width: number;
};
const analyzeEMGProgram = (emgProgram: EMGExerciseDefinition | null) => {
  const emgArray: EMGArrayElement[] = [];
  let timeOffset = 0;

  if (emgProgram) {
    for (const step of emgProgram.emg.program.steps) {
      timeOffset += step.initialRelaxation;
      for (let repetition = 0; repetition < step.repetitions; repetition++) {
        emgArray.push({
          time: timeOffset,
          threshold: step.threshold,
          width: step.workTime,
        });
        timeOffset += step.workTime + step.restTime;
      }
    }
  }

  return {
    totalTime: timeOffset,
    emgArray,
  };
};

export type TimeLineChartOptions = {
  shiftDatasetsOnAxisY?: boolean;
  showTimeMark?: boolean;
  windowWidth?: number;
  yAxisUnit?: string;
  yAxisMax?: number;
  emgProgram?: EMGExerciseDefinition;
  hideChannels?: number[];
};

export const useTimelineChart = (
  timelines: ChannelMap<TimeLinePoint[]>,
  time: Signal,
  options: TimeLineChartOptions,
) => {
  useSignals();
  const chartRef = useRef<HTMLDivElement>(null);
  const echartInstance = useRef<echarts.ECharts | null>(null);
  const echartOptions = useRef<echarts.EChartsOption | null>(null);
  const fontSize = useBreakpointValue({
    base: 16,
    '2xl': 20,
  });

  const {
    shiftDatasetsOnAxisY = false,
    showTimeMark = true,
    windowWidth: windowWidth = 20,
    emgProgram = null,
    yAxisUnit = '%',
    yAxisMax: yMax = 100,
    hideChannels = [],
  } = options;

  const convertDataset = useCallback((dataset: TimeLinePoint[], shift: number) => {
    if (!echartInstance.current || !echartOptions.current) {
      return;
    }

    if (!shift) {
      return dataset;
    }
    const yShift = shift * getYPixelWidth(echartInstance.current) * 5; // 5 is based mockups
    return dataset.map((v, i) => {
      const prevPoint = dataset[i - 1];
      const nextPoint = dataset[i + 1];

      let deltaX = 0;
      let deltaY = 0;

      let calculatedXShift = 0;
      let calculatedYShift = 0;
      if (!v[1]) {
        if (prevPoint && prevPoint[1]) {
          deltaX = v[0] - prevPoint[0];
          deltaY = prevPoint[1] - v[1];
        } else if (nextPoint && nextPoint[1]) {
          deltaX = v[0] - nextPoint[0];
          deltaY = nextPoint[1] - v[1];
        }
      } else {
        calculatedYShift = yShift;
      }

      if (deltaY) {
        calculatedXShift = (deltaX / deltaY) * yShift;
      }

      return [v[0] - calculatedXShift, v[1] - calculatedYShift];
    });
  }, []);

  const getMarkingForSeries = useCallback(() => {
    echartInstance.current?.setOption({});

    const { emgArray } = analyzeEMGProgram(emgProgram);

    const markAreas = emgArray.map(
      v =>
        [
          {
            xAxis: v.time,
            yAxis: 0,
          },
          {
            xAxis: v.time + v.width,
            yAxis: v.threshold,
          },
        ] as [{ xAxis: number; yAxis: number }, { xAxis: number; yAxis: number }],
    );

    const markLines = emgArray.flatMap(
      v =>
        [
          [
            {
              coord: [v.time, 0],
              symbol: 'none',
              lineStyle: { color: colors.egzotechPrimaryColor, type: 'solid', width: 3 },
            },
            { coord: [v.time, v.threshold], symbol: 'arrow' },
          ],
          [
            {
              coord: [v.time + v.width, 0],
              symbol: 'arrow',
              lineStyle: { color: colors.arrowDownColor, type: 'solid', width: 3 },
            },
            { coord: [v.time + v.width, v.threshold], symbol: 'none' },
          ],
          {
            yAxis: v.threshold,
            lineStyle: {
              color: colors.egzotechPrimaryColor,
              type: 'dashed',
              width: 1,
            },
          },
        ] as unknown as MarkLine2DDataItemOption[],
    );

    const series: echarts.EChartsOption['series'] = {
      name: 'threshold',
      type: 'line' as const,
      data: [],
      markLine: {
        symbolSize: [12, 20],
        symbol: ['none', 'none'],
        label: {
          show: false,
        },
        data: markLines,
      },
      markArea: {
        itemStyle: {
          color: `rgba(196, 236, 255, 0.5)`,
        },
        data: markAreas,
      },
    };

    return series;
  }, [emgProgram]);

  const initializeChart = useCallback(() => {
    if (!echartInstance.current) {
      return;
    }
    const rangeData =
      time.value > windowWidth / 2
        ? { min: time.value - windowWidth / 2, max: time.value + windowWidth / 2 }
        : { min: 0, max: windowWidth };
    echartOptions.current = {
      [Symbol.toStringTag]: 'Function',
      xAxis: {
        type: 'value',
        name: '[s]',
        nameGap: 30,
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize,
          fontWeight: 'bold',
        },
        ...rangeData,
        splitNumber: windowWidth,
        animation: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ddd',
          },
        },
        axisLabel: {
          interval: 0,
          animation: false,
          formatter: (_time: number) => {
            const time = Math.floor(_time);
            const seconds = time % 60;
            const minutes = (time - seconds) / 60;
            return `${minutes}:${seconds.toString().padStart(2, '0')}`;
          },
          showMinLabel: false,
          showMaxLabel: false,
          fontSize,
        },
      },
      yAxis: {
        type: 'value',
        nameGap: 30,
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize,
          fontWeight: 'bold',
        },
        axisLabel: {
          fontSize,
          formatter: (value: number) => {
            return `${value}` + (yAxisUnit ? ` ${yAxisUnit}` : '');
          },
        },
        animation: false,
        min: 0,
        max: yMax,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ddd',
          },
        },
      },

      grid: {
        left: '5%',
        right: '5%',
        top: '5%',
        bottom: '10%',
        containLabel: true,
      },
      animation: false,
      tooltip: { show: false },
      toolbox: { show: false },
      title: { show: false },
      series: [{ ...getMarkingForSeries() }],
    } as echarts.EChartsOption;
    echartInstance.current.setOption<echarts.EChartsOption>(echartOptions.current);
  }, [fontSize, getMarkingForSeries, windowWidth, yAxisUnit, yMax, time]);

  const getTimeSeries = useCallback((time: number) => {
    if (!echartInstance.current) {
      return;
    }
    const markLineWidth = 2;
    const dx = getXPixelWidth(echartInstance.current);

    const series = [
      {
        name: 'timemark',
        type: 'line' as const,
        data: [],
        markLine: {
          symbol: 'none',
          silent: true,
          label: {
            show: false,
          },

          data: [
            {
              name: 'left',
              xAxis: time - markLineWidth * dx,
              lineStyle: {
                color: 'white',
                type: 'solid',
                width: markLineWidth,
              },
            },
            {
              name: 'right',
              xAxis: time + markLineWidth * dx,
              lineStyle: {
                color: 'white',
                type: 'solid',
                width: markLineWidth,
              },
            },
            {
              name: 'center',
              xAxis: time,
              lineStyle: {
                color: colors.egzotechPrimaryColor,
                type: 'solid',
                width: markLineWidth,
              },
            },
          ],
        },
      },
    ];
    return series;
  }, []);

  const getDataSeries = useCallback(
    (timelines: ChannelMap<TimeLinePoint[]>, hideChannels: number[]) => {
      if (!echartInstance.current || !echartOptions.current) {
        return;
      }

      let shift = 0;
      const series = timelines
        .entries()
        .filter(v => !hideChannels.includes(v[0]))
        .map(([channelIndex, values]) => {
          const data = convertDataset(values, shiftDatasetsOnAxisY ? shift : 0);
          if (data && data.length) {
            shift++;
          }
          return {
            name: `line${channelIndex}`,
            type: 'line' as const,
            showSymbol: false,
            data,
            animation: false,
            showAllSymbol: false,
            legendHoverLink: false,
            sampling: 'none',
            progressive: false,
            smooth: false,
            color: colors.channel[(channelIndex + 1) as Channel],
          } as echarts.SeriesOption;
        });

      return series;
    },
    [convertDataset, shiftDatasetsOnAxisY],
  );

  const updateTimeMarks = useCallback(
    (time: number, timelines: ChannelMap<TimeLinePoint[]>) => {
      const timeDelta = time - windowWidth / 2;
      if (!echartInstance.current) {
        return;
      }

      const timeWindowStart = timeDelta > 0 ? timeDelta : 0;

      if (echartOptions.current?.xAxis && !Array.isArray(echartOptions.current.xAxis) && timeWindowStart > 0) {
        const timeWindowEnd = timeWindowStart + windowWidth;

        echartOptions.current.xAxis.min = timeWindowStart;
        echartOptions.current.xAxis.max = timeWindowEnd;
      }

      const baseSeries = echartOptions.current?.series as echarts.EChartsOption['series'][];
      const series = getDataSeries(timelines, hideChannels) ?? [];
      const timeSeries = showTimeMark ? getTimeSeries(time) ?? [] : [];

      echartInstance.current?.setOption({
        series: [...baseSeries, ...series, ...timeSeries],
      });
    },
    [getDataSeries, getTimeSeries, hideChannels, showTimeMark, windowWidth],
  );

  useEffect(() => {
    const resizeHandler = () => {
      echartInstance.current?.resize();
      updateTimeMarks(time.value, timelines);
    };

    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  });

  useEffect(() => {
    if (!chartRef.current) return;
    echartInstance.current = echarts.init(chartRef.current, null, {
      renderer: 'canvas',
      devicePixelRatio: 1,
      useDirtyRect: true,
    });

    initializeChart();
    updateTimeMarks(0, timelines);

    return () => {
      echartInstance.current?.dispose();
    };
  }, [initializeChart, timelines, updateTimeMarks]);

  useSignalEffect(() => {
    updateTimeMarks(time.value, timelines);
  });

  return { chartRef, echartInstance };
};
