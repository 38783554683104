import { DeepReadonly, ExoSession } from '@egzotech/exo-session';
import { Recordable } from '@egzotech/exo-session/features/common';
import {
  ExoElectrostimFeature,
  StimChannelConfiguration,
  StimChannelIndex,
  StimPhase,
} from '@egzotech/exo-session/features/electrostim';
import { Logger } from '@egzotech/universal-logger-js';
import { Signal, signal } from 'helpers/signal';
import { ElectrostimChartDataSource } from 'libs/chart-datasources';
import { ConnectedChannelToMuscle } from 'types';
import {
  ChannelRoleInstances,
  getChannelRoles,
} from 'views/+patientId/training/+trainingId/_components/ConnectElectrodes';

import { CalibrationFlowStatesTypedData } from '../common/CalibrationFlow';
import EMGSignal from '../common/EMGSignal';
import EMSCalibration from '../common/EMSCalibration';
import { EMSTimeController } from '../common/EMSTimeController';
import { Recordings, SignalRecorderController } from '../common/SignalRecorderController';
import { Trigger } from '../common/Trigger';
import { TriggerableGroup } from '../common/TriggerableGroup';
import { TriggerThresholdEMG } from '../common/TriggerThresholdEMG';
import { DeviceType } from '../global/DeviceManager';
import { SettingsBuilder } from '../settings/SettingsBuilder';
import { exerciseActionTracker } from '..';

import {
  CPMExerciseDefinition,
  EMSExerciseDefinition,
  GeneratedEMSExerciseDefinition,
  GeneratedExerciseDefinition,
} from './../types/GeneratedExerciseDefinition';
import { Exercise, ExerciseFeature } from './Exercise';
import { FinishedReason } from './FinishedReason';

export interface EMSProgramData {
  /**
   * Flag that indicates if ems program is ready to be started
   */
  active: Signal<boolean>;

  /**
   * Flag that indicates if ems program was started
   */
  started: Signal<boolean>;

  /**
   * Flag that indicates if ems program is running, program automatically stops when all repetitions in all phases are ended
   */
  running: Signal<boolean>;

  /**
   * The reason why exercise is finished.
   * @default exerciseFinished
   */
  finishedReason: Signal<FinishedReason>;

  /**
   * Gets current repetition for the program.
   */
  currentRepetition: Signal<number>;

  currentPhase: Signal<number>;

  currentPhaseData: Signal<DeepReadonly<StimPhase>>;

  waitingForTrigger: Signal<boolean>;

  /**
   * Array of timestamps informing when emg trigger occurred in exercise,
   * if exercise is not triggered, this array should be empty
   */
  triggersTimestamps: Date[];
}

export interface EMSDefinitonData {
  phasesDefinition: Signal<DeepReadonly<StimPhase[]>>;
  phasesRepetition: Signal<number>;
}

export function getRealPhases(editableDefinition: EMSExerciseDefinition | CPMExerciseDefinition) {
  if (!editableDefinition.ems) {
    throw new Error('EMS definition not defined');
  }
  return editableDefinition.ems.program.phases.map(phase => {
    return {
      ...phase,
      channels: editableDefinition.ems!.program.defaultChannelValues.map(v => ({
        ...v,
        ...phase.channels.find(ch => ch.channelIndex === v.channelIndex),
      })),
    };
  });
}

export class EMSExercise implements Exercise {
  static readonly logger = Logger.getInstance('EMSExercise');

  readonly prepared = signal(false, 'EMSExercise.prepared');
  readonly finished = signal(false, 'EMSExercise.finished');

  readonly emsCalibration = new EMSCalibration();

  readonly triggerableGroup = new TriggerableGroup();

  readonly emsTimeController = new EMSTimeController();

  private _programData: EMSProgramData;

  private _exerciseData = {
    totalDuration: signal(0, 'EMSExercise.totalDuration'),
    totalRepetition: signal(0, 'EMSExercise.totalRepetition'),
  };

  private _calibrationData: DeepReadonly<CalibrationFlowStatesTypedData> | null = null;

  private _emgSignal: EMGSignal | null = null;
  private _recordings: Recordings = {};
  private _recorderController: SignalRecorderController | null = null;

  private _triggers: Trigger[] = [];
  private _waitForRelaxationFlag = false;

  private _emsFeature: ExoElectrostimFeature | null = null;

  private _settingsBuilder: SettingsBuilder;
  private _chartDataSource: ElectrostimChartDataSource = new ElectrostimChartDataSource();

  editableDefinition: EMSExerciseDefinition;

  constructor(
    readonly definition: GeneratedEMSExerciseDefinition,
    readonly exerciseName: string | null,
    readonly session: ExoSession,
    readonly deviceType: DeviceType,
  ) {
    this._settingsBuilder = new SettingsBuilder(definition);
    this.editableDefinition = structuredClone(definition as EMSExerciseDefinition);

    this._programData = {
      active: signal(false, 'EMSExercise._programData.active'),
      started: signal(false, 'EMSExercise._programData.started'),
      running: signal(false, 'EMSExercise._programData.running'),
      finishedReason: signal('exerciseFinished', 'EMSExercise._programData.finishedReason'),
      currentPhase: signal(0, 'EMSExercise._programData.currentPhase'),
      currentRepetition: signal(0, 'EMSExercise._programData.currentRepetition'),
      currentPhaseData: signal(getRealPhases(this.editableDefinition)[0], 'EMSExercise._programData.currentPhaseData'),
      waitingForTrigger: signal(true, 'EMSExercise._programData.waitingForTrigger'),
      triggersTimestamps: [],
    };
  }

  get programData() {
    return this._programData;
  }

  get emgSignal() {
    return this._emgSignal;
  }

  get recordings() {
    return this._recordings;
  }

  get settings() {
    return this._settingsBuilder;
  }

  get exerciseData() {
    return this._exerciseData;
  }

  get emsFeature() {
    return this._emsFeature;
  }

  get triggers(): readonly Trigger[] {
    return this._triggers;
  }

  get currentProgramDuration() {
    return this.emsTimeController.currentProgramDuration;
  }

  get currentRepetitionDuration() {
    return this.emsTimeController.currentRepetitionDuration;
  }

  get currentPhaseDuration() {
    return this.emsTimeController.currentPhaseDuration;
  }

  get connectedElectrostimChannels() {
    if (!this._calibrationData) {
      throw new Error('This exercise was never prepared');
    }

    const channelRoles = getChannelRoles(
      this._calibrationData['channel-role-selector']?.channelRolesInstances,
      0, // TODO: get from ExoElectrostimFeature
    );

    return Object.entries(channelRoles)
      .filter(([_, v]) => v.includes('electrostim'))
      .map(([k, _]) => +k);
  }

  get connectedEMGChannels() {
    if (!this._calibrationData) {
      throw new Error('This exercise was never prepared');
    }

    const channelRolesInstances = this._calibrationData['channel-role-selector']?.channelRolesInstances as
      | ChannelRoleInstances
      | undefined;

    if (channelRolesInstances) {
      return Object.values(channelRolesInstances).reduce((prev, v) => {
        Object.entries(v).forEach(([k, v]) => {
          if (v.includes('emg')) prev.push(+k);
        });
        return prev;
      }, [] as number[]);
    }

    const connectedChannelsToMuscles = this._calibrationData['connect-electrodes']?.connectedChannelsToMuscles as
      | ConnectedChannelToMuscle[]
      | undefined;

    return (
      connectedChannelsToMuscles
        ?.filter(v => v.muscle.channelFeature.includes('emg') || v.muscle.channelFeature.includes('emg-pelvic'))
        .map(channel => channel.channelIndex) ?? []
    );
  }

  get connectedTriggerChannels() {
    if (!this._calibrationData) {
      throw new Error('This exercise was never prepared');
    }

    const channelRoles = getChannelRoles(
      this._calibrationData['channel-role-selector']?.channelRolesInstances,
      0, // TODO: get from ExoElectrostimFeature
    );

    if (!channelRoles) {
      throw new Error('Missing channel roles to read triggered channels');
    }

    const result = Object.entries(channelRoles)
      .filter(([_, v]) => v.includes('trigger'))
      .map(([k, _]) => +k);
    return result;
  }

  get chartDataSource() {
    return this._chartDataSource;
  }

  init() {
    if (this._programData.active.peek()) {
      EMSExercise.logger.debug('start', 'Program is already started');
      return;
    }
    if (!this.editableDefinition.ems) {
      throw new Error('Cannot set EMS program without definition');
    }

    this._emsFeature = this.session.activate(ExoElectrostimFeature);
    EMSExercise.logger.debug('activateFeature', `ExoElectrostimFeature is activated`);

    this.emsCalibration.setFeature(this._emsFeature);
    this.emsTimeController.setup({ emsFeature: this._emsFeature });

    this.initializeFeatureCallbacks();

    this._programData.active.value = true;
    this.finished.value = false;
  }

  prepare(calibrationData: DeepReadonly<CalibrationFlowStatesTypedData>): void {
    this._calibrationData = calibrationData;

    // TODO: This exercise uses new initialization method which only calls prepare. We temporarily
    // call `init` manually, but we need to move the code from `init` to here. Init was deprecated and
    // new exercises should not use it. Another possible way to resolve this problem is make `init`
    // a private internal method.
    this.init();

    if (!this.emsFeature) {
      throw new Error('EMS feature is not activated');
    }

    const exportedCalibrations = this._calibrationData['ems-calibration']?.exportedCalibrations as
      | { [key: number]: number }
      | undefined;

    if (!exportedCalibrations) {
      throw new Error('Missing electrostim calibrations for the exercise');
    }

    this._settingsBuilder = new SettingsBuilder(
      (calibrationData['basing-settings']?.definition as GeneratedExerciseDefinition | undefined) ??
        this.editableDefinition,
    );

    this.updateDefinition();
    this.emsFeature.setProgram(this.editableDefinition.ems.program);

    this.emsCalibration.start(this.connectedElectrostimChannels);
    this.emsCalibration.import(exportedCalibrations[0]);
    this.emsCalibration.end();

    this.updateChannelMapping();

    this._programData.currentPhaseData.value = this.getPhaseDataChannelsMapping(0);

    const recordables: Recordable<'single' | 'multi'>[] = [];

    if ((this.connectedEMGChannels.length > 0 || this.connectedTriggerChannels.length > 0) && !this._emgSignal) {
      this._emgSignal = EMGSignal.createFromCalibrationFlow(this.session, calibrationData, [this.triggerableGroup]);
      this._emgSignal.setTriggerChannels(this.connectedTriggerChannels);
      recordables.push(...this._emgSignal.getRecordables());
    }

    if (recordables.length > 0) {
      this._recorderController = new SignalRecorderController(recordables, this.connectedEMGChannels);
    }

    this.prepared.value = true;

    this.initializeTriggerables();
    this.initializeTriggers();
  }

  play() {
    if (!this._programData.active.peek()) {
      EMSExercise.logger.debug('play', 'Cannot play program that is ended or not yet started');
      return;
    }

    if (this._programData.running.peek()) {
      EMSExercise.logger.debug('play', 'Program is already running');
      return;
    }

    if (!this._emsFeature) {
      throw new Error('Cannot play program without fully activated EMS feature');
    }

    if (!this._calibrationData) {
      throw new Error('Cannot play program on during calibration');
    }

    this._emsFeature.start();
    this.triggerableGroup.trigger();
    this.emsTimeController.play();

    this._emgSignal?.enable(this._emgSignal.channels);

    if (this._recorderController?.started) {
      this._recorderController?.resume();
    } else {
      this._recorderController?.start();
    }

    exerciseActionTracker.timePoints.exercise.start = new Date();
    exerciseActionTracker.add('activity', 'play');

    this._programData.started.value = true;
    this._programData.running.value = true;
  }

  pause(options?: { endPause?: boolean }) {
    this._emsFeature?.stop();
    this.emsTimeController.pause();

    if (!this._programData.active.peek()) {
      EMSExercise.logger.debug('pause', 'Cannot pause program that is ended or not yet started');
      return;
    }
    if (!this._programData.running.peek()) {
      EMSExercise.logger.debug('play', 'Program is already stopped');
      return;
    }
    if (!this._emsFeature) {
      throw new Error('Cannot pause program without fully activated CPM and EMS features');
    }

    this._emgSignal?.disable();
    this._recorderController?.pause();

    if (!options?.endPause) {
      exerciseActionTracker.add('activity', 'pause');
    }

    this._programData.running.value = false;
  }

  end(reason: FinishedReason = 'exerciseFinished') {
    this.pause({ endPause: true });

    if (!this.prepared.peek()) {
      EMSExercise.logger.debug('end', 'Cannot end program that is not initialized');
      return;
    }

    if (!this._emsFeature) {
      throw new Error('Cannot end program without fully activated EMS feature');
    }

    if (this._recorderController) {
      this._recordings = this._recorderController.stop();
    }

    if (exerciseActionTracker.timePoints.exercise?.start && !exerciseActionTracker.timePoints.exercise?.end) {
      exerciseActionTracker.activeChannels = this.connectedElectrostimChannels?.concat(this.connectedEMGChannels);
    }

    this._programData.finishedReason.value = reason;
    this._programData.running.value = false;
    this._programData.active.value = false;
    this.finished.value = true;
  }

  destroy() {
    if (!this.prepared.peek()) {
      EMSExercise.logger.debug('destroy', 'Exercise was not yet prepared. There is nothing to destroy.');
    }

    if (!this._emsFeature) {
      EMSExercise.logger.debug('destroy', 'Feature EMS is already disposed');
      return;
    }

    this.end();

    this._emsFeature.dispose();
    this._emsFeature = null;
    this._emgSignal?.dispose();
    this._emgSignal = null;
    this.emsTimeController.dispose();
    this._recorderController = null;
  }

  supports(feature: ExerciseFeature): boolean {
    switch (feature) {
      case 'cable':
        return true;
      default:
        return false;
    }
  }

  setProgram(options?: { update?: boolean; forEMSCalibration?: boolean; withTriggeredEMS?: boolean }) {
    if (!this._emsFeature) {
      throw new Error('Cannot set program without activated features');
    }
    if (options?.forEMSCalibration) {
      this.updateDefinition();
      if (!this.emsCalibration.data.peek().active) {
        this._emsFeature.setProgram(this.editableDefinition.ems.program);
        if (options?.withTriggeredEMS) {
          this.updateChannelMapping();
        }
      }
      return;
    }

    this.updateDefinition();
    this.emsFeature?.setProgram(this.editableDefinition.ems.program);
    this._chartDataSource.setProgram(this.editableDefinition, this.getChannelMapping());
    this.updateChannelMapping();
  }

  updateDefinition() {
    this._settingsBuilder.updateDefinition(this.editableDefinition);
    this._exerciseData.totalDuration.value = this.editableDefinition.ems.program.programTime;
    this._exerciseData.totalRepetition.value = this.editableDefinition.ems.program.phasesRepetition;
  }

  getChannelMapping() {
    if (!this._emsFeature || !this.connectedElectrostimChannels.length) {
      throw new Error('Cannot get channel mapping without electrodes or EMSFeature');
    }
    const mapping: Record<number, number> = {};

    if (
      this.editableDefinition.ems.program.minRequiredChannels &&
      this.editableDefinition.ems.program.minRequiredChannels > 1
    ) {
      let index = 0;
      for (const channel of this.connectedElectrostimChannels) {
        mapping[channel] = this.editableDefinition.ems.program.defaultChannelValues[index].channelIndex;
        index++;
      }
    } else {
      for (const channel of this.connectedElectrostimChannels) {
        mapping[channel] = 0;
      }
    }
    return mapping;
  }

  private updateChannelMapping() {
    const mapping = this.getChannelMapping();
    if (this._emsFeature && mapping) {
      this._emsFeature.setChannelMapping(mapping);
    }
  }

  private getPhaseDataChannelsMapping(phase: number) {
    const phases = getRealPhases(this.editableDefinition)[phase];
    return {
      ...phases,
      channels: this.connectedElectrostimChannels.reduce(
        (acc, realChannelIndex, i) => {
          const virtualChannelIndex =
            this.editableDefinition.ems.program.minRequiredChannels &&
            this.editableDefinition.ems.program.minRequiredChannels > 1
              ? i
              : 0;
          const channels = phases.channels.find(ch => ch.channelIndex === virtualChannelIndex);
          acc.push({
            ...channels,
            channelIndex: realChannelIndex as StimChannelIndex,
          });
          return acc;
        },
        [] as (Partial<StimChannelConfiguration> & {
          channelIndex: StimChannelIndex;
        })[],
      ),
    };
  }

  private initializeFeatureCallbacks() {
    if (!this._emsFeature) {
      throw new Error('Cannot initialize features callbacks without fully activated features');
    }

    const onElectrostimPhaseChangeCallback = (phase: number) => {
      this._programData.currentPhase.value = phase;
      this._programData.currentPhaseData.value = this.getPhaseDataChannelsMapping(phase);
      this._programData.waitingForTrigger.value = this.emsFeature?.canTrigger() ?? false;
    };

    const onElectrostimRepetitionChangeCallback = (repetition: number) => {
      this._programData.currentRepetition.value = repetition;
    };

    const onElectrostimFinishCallback = () => {
      EMSExercise.logger.debug('initializeFeatureCallbacks.onElectrostimFinishCallback', 'End electrostim callback');
      this.finished.value = true;
      this.end();
    };

    const onElectrostimInterupt = () => {
      if (!this._emsFeature) {
        EMSExercise.logger.debug('initializeFeatureCallbacks.onElectrostimInterrupt', 'EMS Feature is not active');
        return;
      }
      this._emsFeature.clearInterrupt();
    };

    this._emsFeature.onPhaseChange = onElectrostimPhaseChangeCallback;
    this._emsFeature.onRepetitionChange = onElectrostimRepetitionChangeCallback;
    this._emsFeature.onFinish = onElectrostimFinishCallback;
    this._emsFeature.onInterrupt = onElectrostimInterupt;
  }

  private initializeTriggerables() {
    if (!this._emsFeature) {
      throw new Error('Cannot initialize triggerables without activated EMS feature');
    }

    if (this.definition.ems.program.phases?.some(phase => !!phase.needsTrigger)) {
      this.triggerableGroup.add('ems', this._emsFeature);
      this.triggerableGroup.add('ems/waiting-for-trigger', {
        trigger: () => (this._programData.waitingForTrigger.value = false),
        canTrigger() {
          return true;
        },
      });
      this.triggerableGroup.addCondition('ems/can-trigger', () => Boolean(this._emsFeature?.canTrigger()));
    }
  }

  private initializeTriggers() {
    this._triggers = [];

    if (this._emgSignal) {
      for (let i = 0; i < this.connectedTriggerChannels.length; i++) {
        const emgChannelTrigger = this.connectedTriggerChannels[i];

        this._triggers.push(new TriggerThresholdEMG(this._emgSignal, emgChannelTrigger));
      }
    }

    if (this._triggers.length > 0 && this.definition.ems.program.phases?.some(phase => !!phase.needsTrigger)) {
      this.triggerableGroup.addCondition('active-signal/can-trigger', () => {
        if (this._waitForRelaxationFlag) {
          // wait until all signals are below their thresholds
          this._waitForRelaxationFlag = this._triggers.some(v => v.isTriggered());
          if (this._waitForRelaxationFlag) {
            return false;
          }
        }
        return this._triggers.every(v => v.isTriggered());
      });
    }
  }
}
